
export default {
  data() {
    return {
      resources: [
        {
          title: 'Listing Rules',
          cards: [
            {
              type: 'pdf',
              title: 'Direct Market Listing & Trading Rules Booklet ',
            },
            {
              type: 'pdf',
              title: 'Foreign ETF’s Listing & Disclosure Rules Booklet',
            },
            {
              type: 'pdf',
              title: 'Investment Funds Listing and Trading Rules Booklet ',
            },
            {
              type: 'pdf',
              title: 'REITs Listing Rules Booklet ',
            },
            {
              type: 'pdf',
              title: 'Free Zone Companies Listing Regulations ',
            },
          ],
        },

        {
          title: 'FAQs',
          cards: [
            {
              type: 'pdf',
              title: 'Disclosures & Transparency Rules ',
            },
            {
              type: 'web',
              title: 'Corporate Governance Rules',
            },
            {
              type: 'web',
              title: 'Price Stabilization Regulations ',
            },
          ],
        },
      ],
    }
  },
}
