
export default {
  name: 'FaqAccordion',
  props: {
    section: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    faqs: [],
  }),
  async fetch() {
    try {
      const response = await fetch('https://api2.dfm.ae/web/widgets/v1/data', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `Command=faqs&section=${this.section}&Language=${this.$i18n.locale}`,
      })

      var data = await response.json()
      this.faqs = data.Children
    } catch (error) {
      // console.log(error)
    }
  },
}
