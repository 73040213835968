
export default {
  name: 'ErrorLayout',
  layout: 'fullScreen',
  data() {
    return {
      hide: true,
    }
  },
  computed: {
    404() {
      return this.$route.hash === '#404'
    },
  },
  mounted() {
    // console.log('404 mounted')
    var path = this.$route.fullPath
    path = path.replace('/en', '')
    path = path.replace('/ar', '')

    // fix efsah link

    if (path.includes('/documents/efsah')) {
      window.location.href = `https://feeds.dfm.ae${path}`
      return false
    }

    if (process.env.NODE_ENV === 'production') {
      if (this.$route.hash !== '#404') {
        // console.log(`https://assets.dfm.ae${path}`)
        window.location.href = `https://assets.dfm.ae${path}`
      } else if (this.$route.hash == '#404') {
        this.hide = false
      }
    } else {
      this.hide = false
    }
  },
}
